<template>
  <div>
    <v-container fluid class="pb-12">
      <v-row>
        <v-snackbar
          style="z-index: 40;"
          color="success"
          v-model="hasSaved"
          :timeout="2000"
          absolute
          top>
          {{ $t('UPDATINGOK') }}
        </v-snackbar>
        <v-col cols="12">
          <v-row v-if="isChangeData">
            <v-col cols="12">
              <v-btn outlined @click="save" class="d-flex d-sm-none mb-2" block x-large color="success">
                <v-icon left>mdi-content-save</v-icon>
                {{ $t('SAVECHANGE') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-card class="mx-auto">
            <v-toolbar flat>
              <v-toolbar-title>
              <h1 class="headline">
                  <v-icon color="primary" class="mb-1 mr-2">mdi-cog</v-icon>
                  {{ $t('WEBSETTINGS') }}</h1>
              </v-toolbar-title>
              <v-spacer></v-spacer>
                  <v-row v-if="isChangeData" class="d-inline justify-end">
                    <v-btn outlined @click="save" class="d-none d-sm-flex ml-auto mr-4" large color="success">
                      <v-icon left>mdi-content-save</v-icon>
                      {{ $t('SAVECHANGE') }}
                    </v-btn>
                  </v-row>
                <v-icon color="grey" class="mb-1 mr-2">mdi-dots-vertical</v-icon>
            </v-toolbar>

            <v-card-text>
              <v-row>
                <v-col>
                  <h2 class="title font-weight-regular">{{ $t('settings.SITENAME') }}</h2>
                  <p>{{ $t('settings.SITENAMEMESSAGE') }}.</p>
                  <v-divider class="my-4"></v-divider>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :change="toggleChangeData()"
                        dense
                        outlined
                        v-model="websiteStore.website.title"
                        :label="$t('settings.SITELABEL')">
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <p>{{ $t('settings.SITEDESCRIPTION') }}</p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-col>
                  <h2 class="title font-weight-regular">{{ $t('settings.SITEURL') }}</h2>
                  <v-divider class="my-4"></v-divider>
                  <v-row>
                    <v-col cols="12">
                      <p class="body-1">{{ $t('settings.SITEURLMESSAGE') }}</p>
                    </v-col>
                  </v-row>
                  <v-divider class="my-4"></v-divider>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <p class="body-1">{{ $t('settings.DOMAINCONNECT') }}.</p>
                      <v-card color="grey lighten-3" elevation="0">
                        <v-card-text class="py-2 px-4 flex-row text-body-1 align-center">  
                          <v-text-field
                            @change="toggleChangeData"
                            :label="$t('settings.SITEPREFIX')"
                            hide-details
                            class="py-1"
                            dense
                            outlined
                            v-model="websiteStore.website.webUrl.name">
                          </v-text-field>
                          <p class="my-0">https://<span class="font-weight-medium">{{ websiteStore.website.webUrl.name }}</span>.arigato.ai</p>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col align="center" align-self="center" cols="12" sm="6">
                      <p class="body-1">{{ $t('settings.OWNDOMAINCONNECT') }}.</p>
                      <v-btn outlined color="primary" x-large block elevation="0">
                        <v-icon left>mdi-domain-plus</v-icon>
                        {{ $t('settings.STARTNOW') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-col>
                  <h2 class="title font-weight-regular">Favicon</h2>
                  <v-divider class="my-4"></v-divider>
                  <v-row>
                    <v-col cols="12">
                      <p class="body-1">{{ $t('settings.FAVICONMESSAGE') }}.</p>
                    </v-col>
                  </v-row>
                  <v-divider class="my-4"></v-divider>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <p class="subtitle-1">{{ $t('settings.YOURFAVICON') }}</p>
                      <p class="subtitle-2">{{ $t('settings.FAVICONMESSAGE2') }}</p>
                      <v-row justify="space-around" class="d-flex mt-2 mx-4" align="center">
                        <v-hover>
                          <template v-slot:default="{ hover }">
                            <v-avatar rounded="lg" class="" size="100">
                              <v-img
                              conver
                              :src="require(`@/assets/airviva/${websiteStore.website.favicon}`)" />
                              <v-fade-transition>
                                <v-overlay
                                  v-if="hover"
                                  absolute
                                  color="primary">
                                  <v-btn color="accent" fab small>
                                    <v-icon color="primary">
                                      mdi-camera
                                    </v-icon>
                                  </v-btn>
                                </v-overlay>
                              </v-fade-transition>
                            </v-avatar>
                            </template>
                        </v-hover>
                        <v-avatar rounded="lg" class="" size="80">
                          <v-img
                          conver
                          :src="require(`@/assets/airviva/${websiteStore.website.favicon}`)" />
                        </v-avatar>
                        <v-avatar rounded="lg" class="" size="60">
                          <v-img
                          conver
                          :src="require(`@/assets/airviva/${websiteStore.website.favicon}`)" />
                        </v-avatar>
                        <v-avatar rounded="lg" class="" size="40">
                          <v-img
                          conver
                          :src="require(`@/assets/airviva/${websiteStore.website.favicon}`)" />
                        </v-avatar>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <p class="subtitle-1 text-center">{{ $t('settings.FAVICONDEMO') }}</p>
                      <p class="caption d-flex justify-center">
                        <v-img 
                          class="rounded-lg cursor-pointer"
                          max-height="180" 
                          max-width="340"
                          :src="require(`@/assets/airviva/demofav.jpg`)" 
                          alt="airviva demo fav icon">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="35"
                                  indeterminate
                                  width="2"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                        </v-img>
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-col>
                  <h2 class="title font-weight-regular">{{ $t('settings.SHAREIMAGE') }}</h2>
                  <v-divider class="my-4"></v-divider>
                  <v-row>
                    <v-col cols="12">
                      <p class="body-1">{{ $t('settings.SHAREIMAGEMESSAGE') }}.</p>
                    </v-col>
                  </v-row>
                  <v-divider class="my-4"></v-divider>
                  <v-row>
                    <v-col cols="12" sm="4" lg="3">
                      <v-img 
                        class="rounded-lg cursor-pointer"
                        max-width="600" 
                        max-height="200" 
                        :src="require(`@/assets/${websiteStore.website.socialImage}`)" 
                        alt="Air Viva Social Media">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="35"
                                indeterminate
                                width="2"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                      </v-img>
                    </v-col>
                    <v-col cols="12" sm="8" lg="9">
                      <p class="body-2">{{ $t('settings.SHAREIMAGEMESSAGE2') }}.<br /> <span class="text-weight-bold">{{ $t('NOTE') }}:</span> {{ $t('settings.SHAREIMAGEMESSAGE3') }}. </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-col>
                  <h2 class="title font-weight-regular">Nombre de usuario de Facebook</h2>
                  <v-divider class="my-4"></v-divider>
                  <v-row>
                    <v-col cols="12">
                      <p class="body-1">Facebook necesita tu nombre de usuario personal para verificar los Me Gusta que puedas recibir en tu página web. Para moderar los comentarios, agrega la caja de Facebook Comments en el Editor.</p>
                    </v-col>
                  </v-row>
                  <v-divider class="my-4"></v-divider>
                  <v-row>
                    <v-col cols="12" sm="8">
                      <p class="body-1">Ingresa tu nombre de usuario personal de Facebook.</p>
                      <p class="caption">Para activar estos cambios, publica tu página web desde el Editor.</p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-col>
                  <h2 class="title font-weight-regular">{{ $t('settings.SITEHISTORY') }}</h2>
                  <v-divider class="my-4"></v-divider>
                  <v-row>
                    <v-col cols="12" sm="8">
                      <p class="body-1">{{ $t('settings.SITEHISTORYMESSAGE') }}.</p>
                    </v-col>
                    <v-col class="text-right" cols="12" sm="4">
                      <v-btn x-large block outlined color="primary">
                        <v-icon left>mdi-backup-restore</v-icon>
                        {{ $t('settings.RESTOREVERSIONS') }}</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="pb-4">
            </v-card-actions>
          </v-card>
        </v-col>
        
      </v-row>

    </v-container>
  </div>
</template>

<script>
import { useWebsiteStore } from '@/stores/websiteStore'

export default {
  name: "settings",
  props: {
    windowWidth: Number,
    saveDataAll: Function,
  },
  data() {
    return {
      websiteStore: useWebsiteStore(),
      hasSaved: false,
      isChange: false,
      initialData: Object.assign({}, useWebsiteStore().website) 
    }
  },
  computed: {
    isChangeData() {
      return this.isChange;
    }
  },
  methods: {
    save () {
      this.hasSaved = true;
      this.saveDataAll();
      this.initialData = Object.assign({}, useWebsiteStore().website);
      this.isChange= false;
    },
    toggleChangeData () {
      if (Object.entries(this.websiteStore.website).toString() === Object.entries(this.initialData).toString()) {
        this.isChange = false;
      } else {
        this.isChange = true;
      }
    }
  },
}
</script>

<style>

</style>
